<template>
  <div>
    <!-- Header Section -->
    <panelheader v-if="sidePanelDataObj.headerDataObj" :header-data-obj="sidePanelDataObj.headerDataObj"></panelheader>

    <!-- Action Bar Section -->
    <actionbar v-if="sidePanelDataObj.actionBarDataObj" :action-bar-data-obj="sidePanelDataObj.actionBarDataObj"></actionbar>

    <!-- Summary Section -->
    <panelsummary v-if="sidePanelDataObj.infoSectionDataObj" :info-section-data-obj="sidePanelDataObj.infoSectionDataObj"></panelsummary>

    <!-- Tabs Section -->
    <tabs v-if="sidePanelDataObj.iFrame" :tab-data-obj="sidePanelDataObj.tabSectionDataObj" :iFrame="sidePanelDataObj.iFrame"></tabs>
  </div>
</template>

<script>
  import panelheader from "@/components/_universal/sidepanel/sidepanel-header";
  import actionbar from "@/components/_universal/sidepanel/sidepanel-action-bar";
  import panelsummary from "@/components/_universal/sidepanel/sidepanel-summary-info";
  import tabs from "@/components/_universal/sidepanel/sidepanel-tabs";
  // import {ProjectDetails} from "@/components";
  import ProjectController from "@/controllers/projects-controller";
  import {mapActions, mapState} from "vuex";
  export default {
    name: "SearchDetails",
    components: {
      panelheader,
      actionbar,
      panelsummary,
      tabs,
    },
    data() {
      return {
        currentComponent: "ProjectDetails",
        projectId: null,
        sidePanelDataObj: {},
        item: {},
      };
    },
    computed: {
      ...mapState({
        previousRoute: (state) => state.Projects.previousRoute,
      }),
    },
    methods: {
      ...mapActions(["setPreviousRoute"]),
      async loadData() {
        this.item = this.$store.getters.getSidePanel;
        //Ensure the side bar is open
        if (this.item.isOpen === true) {
          //Initialize Controller and pass data to be loaded/displayed
          let projectController = new ProjectController();

          //Set Header Properties to be passed in as prop values
          this.sidePanelDataObj = await projectController.main(this.item.data);
        }
      },
    },

    mounted() {
      this.loadData();
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        // access any getter/action here via vm.$store
        // avoid importing the store singleton and thus creating hard dependencies
        vm.setPreviousRoute(from);
      });
    },
    beforeDestroy() {
      if (this.previousRoute.name === "SearchPage") {
        this.$router.push(this.previousRoute.fullPath);
      }
    },
  };
</script>
